import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Ranking from './Ranking';
import 'moment/locale/ko';
import ProgressView from './ProgressView'; // Assuming ProgressView is in components folder

moment.locale('ko');
moment.tz.setDefault('Asia/Seoul');

const Home = () => {
    const [problemList, setProblemList] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [loadingProblems, setLoadingProblems] = useState(true);
    const [loadingSolutions, setLoadingSolutions] = useState(true);

    useEffect(() => {
        const fetchProblemList = async () => {
            setLoadingProblems(true);
            try {
                const response = await fetch('https://dsmb.api.goding.us/problemList?page=1');
                if (!response.ok) {
                    throw new Error('네트워크 상태가 좋지 않습니다. 다시 시도해주세요.');
                }
                const data = await response.json();
                if (data.success) {
                    setProblemList(data.problems.slice(0, 10));
                } else {
                    console.error('서버 오류:', data.message);
                }
            } catch (error) {
                console.error('문제 목록을 불러오는 중 오류가 발생했습니다:', error);
            } finally {
                setLoadingProblems(false);
            }
        };

        const fetchSolutions = async () => {
            setLoadingSolutions(true);
            try {
                const response = await fetch('https://dsmb.api.goding.us/allSolutions?page=1');
                if (!response.ok) {
                    throw new Error('네트워크 상태가 좋지 않습니다. 다시 시도해주세요.');
                }
                const data = await response.json();
                if (data.success) {
                    setSolutions(data.solutions.slice(0, 10));
                } else {
                    console.error('서버 오류:', data.message);
                }
            } catch (error) {
                console.error('솔루션 목록을 불러오는 중 오류가 발생했습니다:', error);
            } finally {
                setLoadingSolutions(false);
            }
        };

        fetchProblemList();
        fetchSolutions();
    }, []);

    return (
        <div className="px-4">
            <div className="flex gap-0 md:gap-4">
                <div className="w-full md:w-3/4">
                    <div className="p-2">
                        <div className="flex">
                            <h2 className="text-xl font-semibold mb-2">문제 목록</h2>
                            <div className="flex-grow"></div>
                        </div>
                        {loadingProblems ? (
                            <ProgressView text="문제 불러오는 중" length="3" />
                        ) : (
                            <ul>
                                {problemList.map(problem => (
                                    <li key={problem.pid} className="border-t border-gray-300 py-2">
                                        <Link to={`/problem/${problem.pid}`}>
                                            <div className="flex mb-1">
                                                <p className="text-lg font-semibold text-left line-clamp-2">{problem.problem_nm}</p>
                                                <div className="flex-grow"></div>
                                            </div>
                                            <div className="flex mb-2">
                                                <p className="text-sm text-gray-600 text-left line-clamp-3">{problem.problem_desc}</p>
                                                <div className="flex-grow"></div>
                                            </div>
                                            <div className="flex">
                                                <Link to={`/user/${problem.handle}`} className="text-sm font-semibold text-gray-800">
                                                    <span>{problem.name}님</span>
                                                    {problem.isAdmin ? (
                                                        <>
                                                            <i className="text-sm text-primary bi bi-patch-check-fill ml-1"></i>
                                                            <span className="ml-1 text-primary font-semibold">관리자</span>
                                                        </>
                                                    ) : null}
                                                    <span className="ml-2 text-gray-500">{problem.point}P</span>
                                                </Link>
                                                <div className="flex-grow"></div>
                                                <p className="text-sm text-gray-800 mr-1">{moment(problem.problem_timestamp).fromNow()}</p>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="p-2 mt-2">
                        <div>
                            <div className="flex">
                                <h2 className="text-xl font-semibold mb-2">솔루션 목록</h2>
                                <div className="flex-grow"></div>
                            </div>
                            {loadingSolutions ? (
                                <ProgressView text="솔루션 불러오는 중" length="3" />
                            ) : (
                                <ul>
                                    {solutions.map(solution => (
                                        <li key={solution.sid} className="border-t border-gray-300 py-2">
                                            <div className="flex mb-1">
                                                <div className="flex">
                                                    <Link to={`/problem/${solution.target_pid}`} className="text-sm font-semibold text-gray-800 p-1 bg-gray-200 rounded-full mr-2">
                                                        <span>#{solution.target_pid} - {solution.problem_nm.substring(0, 20)}</span>
                                                    </Link>
                                                </div>
                                                <Link to={`/solution/${solution.sid}`} className="text-lg font-semibold line-clamp-2">
                                                    <p className="text-lg font-semibold text-left line-clamp-2">{solution.solution_desc}</p>
                                                </Link>
                                                <div className="flex-grow"></div>
                                            </div>
                                            <div className="flex">
                                                <Link to={`/user/${solution.handle}`} className="text-sm font-semibold text-gray-800">
                                                    <span>{solution.name}님</span>
                                                    {solution.isAdmin ? (
                                                        <>
                                                            <i className="text-sm text-primary bi bi-patch-check-fill ml-1"></i>
                                                            <span className="ml-1 text-primary font-semibold">관리자</span>
                                                        </>
                                                    ) : null}
                                                    <span className="ml-2 text-gray-500">{solution.point}P</span>
                                                </Link>
                                                <div className="flex-grow"></div>
                                                <p className="text-sm text-gray-800 mr-1">{moment(solution.solution_timestamp).fromNow()}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hidden md:block w-0 md:w-1/4">
                    <div className="p-2">
                        <div className="flex mb-1">
                            <h3 className="text-lg font-semibold mb-2"><i className="bi bi-list mr-2" />메뉴</h3>
                            <div className="flex-grow"></div>
                        </div>
                        <div className="flex flex-col gap-2 mb-4">
                            <Link to="/new/problem" className="border border-gray-700 text-gray-700 rounded-xl p-2 font-semibold w-full"><i className="bi bi-plus-circle-fill mr-2" />새 문제 등록하기</Link>
                        </div>
                        <div className="flex mb-1">
                            <h3 className="text-lg font-semibold mb-2"><i className='bi bi-trophy-fill mr-2' />랭킹</h3>
                            <div className="flex-grow"></div>
                        </div>
                        <Ranking />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;