import { useState } from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  const handle = localStorage.getItem('handle');
  const credential = localStorage.getItem('credential');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-50 md:hidden" onClick={toggleMobileMenu}>
          {/* Sidebar Menu */}
          <div className="bg-gray-200 w-3/4 h-full p-4 absolute top-0 left-0 transform transition-transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}">
            <ul className="flex flex-col space-y-4 text-gray-900 font-semibold">
              <li><Link to="/" onClick={toggleMobileMenu}>홈</Link></li>
              <li><Link to="/ranking" onClick={toggleMobileMenu}>랭킹</Link></li>
              {handle && credential ? (
                <>
                  <li><Link to={`/user/${handle}`} onClick={toggleMobileMenu}>내 정보</Link></li>
                    <li><Link to="/new/problem" onClick={toggleMobileMenu}>문제 등록</Link></li>
                  <li><a href="javascript:void(0)" onClick={() => { localStorage.clear(); window.location.href = '/'; toggleMobileMenu(); }}>로그아웃</a></li>
                </>
              ) : (
                <>
                  <li><Link to="/register" onClick={toggleMobileMenu}>회원가입</Link></li>
                  <li><Link to="/login" onClick={toggleMobileMenu}>로그인</Link></li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}

      {/* Navigation Bar */}
      <nav>
        <div className="flex justify-between items-center bg-gray-200 p-4 rounded-2xl m-4 relative z-10">
          {/* Logo and Title */}
          <Link to="/" className="flex items-center">
            <img src="https://i.imgur.com/tkne5tn.png" alt="DDSHS Logo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold text-gray-900 mr-1">Math Bounty</span>
          </Link>

          {/* Hamburger Button (Mobile Only) */}
          <button
            className="md:hidden text-gray-900 hover:text-gray-700 focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
            </svg>
          </button>

          {/* Desktop Navigation */}
          <ul className="hidden md:flex space-x-4 text-gray-900 font-semibold mr-2">
            <li><Link to="/">홈</Link></li>
            <li><Link to="/ranking">랭킹</Link></li>
            {handle && credential ? (
              <>
                <li><Link to={`/user/${handle}`}>내 정보</Link></li>
                <li><Link to="/new/problem">문제 등록</Link></li>
                <li><a href="javascript:void(0)" onClick={() => { localStorage.clear(); window.location.href = '/'; }}>로그아웃</a></li>
              </>
            ) : (
              <>
                <li><Link to="/register">회원가입</Link></li>
                <li><Link to="/login">로그인</Link></li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Nav;