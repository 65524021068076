const Footer = () => {
    return (
        <footer className="mt-6">
            <div className="flex mx-4 my-4 bg-white p-2 text-gray-600 border-t border-gray-200">
                <div className="text-sm font-semibold mt-2">
                    <div className="flex mb-2">
                        <h1 className="text-xl font-bold text-gray-600">동신 매쓰 바운티</h1>
                        <div className="flex-grow" />
                    </div>
                    <div className="flex">
                        <p className="text-left" style={{ fontWeight: 350 }}>본 사이트는 대전동신과학고 학생들에게 수학 문제 풀이 경험과 성취를 제공하기 위해 개발되었습니다.</p>
                        <div className="flex-grow" />
                    </div>
                    <div className="flex mt-2">
                        <p className="text-left text-xs" style={{ fontWeight: 350 }}>Developed with ❤️ by <a href="https://goding.us/" target="_blank" rel="noreferrer" className="underline">Team Goding</a> · <a href="mailto:support@goding.us" className="underline"> 문의하기</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;