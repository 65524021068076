import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import ProgressView from './ProgressView';

moment.tz.setDefault("Asia/Seoul");

const Solution = () => {
    const { sid } = useParams();
    const [solution, setSolution] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [rating, setRating] = useState(0); // New state for rating
    const [loadingSolution, setLoadingSolution] = useState(true);
    const [loadingPDF, setLoadingPDF] = useState(true);

    useEffect(() => {
        const fetchSolutionData = async () => {
            setLoadingSolution(true);
            try {
                const solutionResponse = await fetch(`https://dsmb.api.goding.us/solution/${sid}`);
                const solutionData = await solutionResponse.json();

                if (!solutionResponse.ok || !solutionData.success) {
                    throw new Error(solutionData.message || 'Solution not found.');
                }

                setSolution(solutionData.solution);
                setRating(solutionData.solution.rating || 0);

                setLoadingPDF(true);
                const pdfResponse = await fetch(`https://dsmb.api.goding.us/solution/file/${sid}`);
                if (!pdfResponse.ok) {
                    throw new Error('Error fetching PDF file.');
                }
                const pdfBlob = await pdfResponse.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfFile(pdfUrl);
            } catch (error) {
                console.error('Error fetching solution or PDF:', error);
            } finally {
                setLoadingSolution(false);
                setLoadingPDF(false);
            }
        };

        fetchSolutionData();

        return () => {
            if (pdfFile) {
                URL.revokeObjectURL(pdfFile);
            }
        };
    }, [sid]);

    const handleDelete = async () => {
        if (window.confirm('정말로 이 솔루션을 삭제하시겠습니까?')) {
            try {
                const handle = localStorage.getItem('handle');
                const credential = localStorage.getItem('credential');

                const response = await fetch('https://dsmb.api.goding.us/admin/deleteSolution', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ handle, credential, sid })
                });

                const data = await response.json();
                if (data.success) {
                    alert('솔루션 삭제가 완료되었습니다.');
                    window.location.href = '/';
                } else {
                    alert(`솔루션 삭제에 실패했습니다: ${data.message}`);
                }
            } catch (error) {
                console.error('솔루션 삭제 중 오류가 발생했습니다:', error);
                alert('솔루션 삭제 중 오류가 발생했습니다.');
            }
        }
    };

    const handleRateSolution = async (newRating) => {
        try {
            const handle = localStorage.getItem('handle');
            const credential = localStorage.getItem('credential');

            const response = await fetch('https://dsmb.api.goding.us/admin/rateSolution', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ handle, credential, sid, rating: newRating })
            });

            const data = await response.json();
            if (data.success) {
                setRating(newRating); // Update the rating locally
                alert('솔루션 평가가 완료되었습니다.');
            } else {
                alert(`솔루션 평가에 실패했습니다: ${data.message}`);
            }
        } catch (error) {
            console.error('솔루션 평가 중 오류 발생:', error);
            alert('솔루션 평가 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className="px-4 pb-4">
            {loadingSolution ? (
                <div><ProgressView text="솔루션 불러오는 중" length="3" /></div>
            ) : solution ? (
                <div className="bg-white rounded-lg shadow-md p-6 border">
                    <div className="flex items-center mb-2">
                        <h1 className="text-3xl font-bold mb-4 text-gray-800">Solution #{solution.sid}</h1>
                        <div className="flex-grow"></div>
                        <Link to={`/problem/${solution.target_pid}`} className="text-gray-600 hover:underline font-semibold text-sm">
                            #{solution.target_pid} {solution.problem_nm} <i className="bi bi-chevron-right text-sm" />
                        </Link>
                    </div>

                    {localStorage.getItem('isAdmin') === '1' && (
                        <div className="border border-gray-400 p-4 rounded-xl mb-4">
                            <div className="flex text-gray-600 font-semibold mb-2">
                                <i className="bi bi-tools mr-2" /> 관리자 도구
                            </div>

                            <div className="flex font-semibold text-sm">

                                <div className="flex items-center mr-2">
                                    <span className="mr-2 font-semibold text-gray-600">솔루션 평가하기</span>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <button key={star} onClick={() => handleRateSolution(star)}>
                                            <i className={`bi bi-star-fill mr-1 text-${star <= rating ? 'yellow-500' : 'gray-400'}`}></i>
                                        </button>
                                    ))}
                                </div>
                                <button
                                    onClick={handleDelete}
                                    className="hover:underline text-red-600"
                                >
                                    <i className="bi bi-trash mr-1" />솔루션 삭제
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Solution Metadata */}
                    <div className="mb-6">
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-person-fill mr-1" />작성자</span>
                            <span className="ml-2 font-semibold">{solution.name}님</span>

                            {solution.isAdmin ? (
                                <>
                                    <i className="text-sm text-primary bi bi-patch-check-fill ml-1"></i>
                                    <span className="ml-1 text-primary font-semibold">관리자</span>
                                </>
                            ) : null}
                            <span className="ml-2 text-gray-500">{solution.point}P</span>
                        </div>
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-calendar-event-fill mr-1" />작성일</span>
                            <span className="ml-2">
                                {moment(solution.solution_timestamp).format('YYYY년 MM월 DD일 HH시 mm분')}
                            </span>
                        </div>
                        <div className="flex items-center text-gray-600 text-sm">
                            <span className="font-semibold text-gray-800">
                                <i className="bi bi-star-fill mr-1" />평가
                            </span>
                            {rating > 0 ? (
                                <span className="ml-2 font-semibold text-yellow-500">{rating}점</span>
                            ) : (
                                <span className="ml-2 font-semibold">평가되지 않음</span>
                            )}
                        </div>
                    </div>

                    {/* Solution Description */}
                    <div className="mb-8">
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <h3 className="text-xl font-semibold mb-2">솔루션 설명</h3>
                            <div className="flex-grow" />
                        </div>
                        <p className="text-gray-800 text-left">{solution.solution_desc}</p>
                    </div>

                    <div className="border rounded-lg p-2">
                        {loadingPDF ? (
                            <div className="text-primary"><ProgressView text="파일 불러오는 중" length="3" /></div>
                        ) : pdfFile ? (
                            <iframe
                                src={pdfFile}
                                title="Solution PDF"
                                width="100%"
                                height="800px"
                            />
                        ) : (
                            <div>PDF를 불러올 수 없습니다.</div>
                        )}
                    </div>

                </div>
            ) : (
                <div>솔루션을 찾을 수 없습니다.</div>
            )}
        </div>
    );
};

export default Solution;