import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({
        handle: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { handle, password } = formData;

        try {
            const response = await fetch('https://dsmb.api.goding.us/signIn', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ handle, password })
            });

            const data = await response.json();
            if (data.success) {
                alert('로그인에 성공했습니다.');
                localStorage.setItem('email', data.email);
                localStorage.setItem('grade', data.grade);
                localStorage.setItem('classnum', data.classnum);
                localStorage.setItem('student_code', data.student_code);
                localStorage.setItem('name', data.name);
                localStorage.setItem('handle', data.handle);
                localStorage.setItem('credential', data.credential);
                localStorage.setItem('isAdmin', data.isAdmin);
                window.location.href = '/';
            } else {
                alert(`로그인에 실패했습니다: ${data.message}`);
            }
        } catch (error) {
            console.error('로그인 중 오류가 발생했습니다:', error);
            alert('로그인 중 오류가 발생했습니다.');
        }
    };

    return (
        <div>
            <div className="rounded-xl mx-4">
                <div className="flex items-center rounded-t-lg p-4">
                    <div className="text-gray-600 text-2xl font-bold">로그인<i className="bi bi-person-fill ml-2" /></div>
                    <div className="flex-grow" />
                    <div className="text-gray-600 text-sm font-semibold">
                        계정이 없으신가요? <Link to="/register" className="underline font-bold">회원가입</Link>
                    </div>
                </div>
                <div className="p-4">
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <label htmlFor="handle" className="text-gray-600 ml-1 font-semibold">아이디</label>
                                <div className="flex-grow" />
                            </div>
                            <input
                                type="text"
                                id="handle"
                                name="handle"
                                value={formData.handle}
                                onChange={handleChange}
                                className="rounded-lg p-2 bg-white border border-gray-600"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <label htmlFor="password" className="text-gray-600 ml-1 font-semibold">비밀번호</label>
                                <div className="flex-grow" />
                            </div>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="rounded-lg p-2 bg-white border border-gray-600"
                                required
                            />
                        </div>
                        <button type="submit" className="mt-4 bg-primary text-white font-semibold p-2 rounded-lg">
                            로그인
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
