import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgressView from './ProgressView'; // Assuming ProgressView is in the components folder

function Ranking() {
  const [ranking, setRanking] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchRanking = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await fetch('https://dsmb.api.goding.us/ranking');
        if (!response.ok) throw new Error('Network response was not ok.');
        const data = await response.json();
        setRanking(data.users);
      } catch (error) {
        setError('랭킹을 불러오는데 실패했습니다.');
        console.error("Error fetching ranking:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching, regardless of success or failure
      }
    };

    fetchRanking();
  }, []);

  if (error) {
    return <div>오류: {error}</div>;
  }

  // Show ProgressView while loading
  if (loading) {
    return <span><ProgressView text="랭킹 불러오는 중" length="3" /></span>;
  }

  return (
    <div className="container mx-auto">
      <ul className="space-y-4">
        {ranking.map((user, index) => (
          <Link to={`/user/${user.handle}`} key={index} className="flex items-center">
            <li key={index} className="flex items-center">
              <div className="relative mr-4">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center text-white 
                  ${index === 0 ? 'bg-yellow-500 shadow-lg shadow-yellow-500/50' :
                      index === 1 ? 'bg-gray-400 shadow-lg shadow-gray-400/50' :
                        index === 2 ? 'bg-yellow-800 shadow-lg shadow-yellow-800/50' :
                          'bg-gray-300'}`}
                  style={{ zIndex: 1 }}
                ></div>
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -mt-2 text-xs font-semibold"
                  style={{ zIndex: 2 }}
                >
                  {index + 1}
                </span>
              </div>
              <div className="flex">
                <div className="flex-grow" />
                {/* <span className="font-medium">{user.student_code} </span> */}
                <span className="mr-4 font-medium">{user.name}님</span>
                <span className="mr-1 font-medium text-gray-600">{user.point}P</span>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default Ranking;