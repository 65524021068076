import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Register = () => {
    const [formData, setFormData] = useState({
        email: '',
        handle: '',
        password: '',
        password_confirm: '',
        student_code: '',
        name: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { email, handle, password, password_confirm, student_code, name } = formData;
        
        // Validate form data (you can add more validations if needed)
        if (password !== password_confirm) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        // Extract grade and classnum from student_code
        if (student_code.length !== 4) {
            alert('학번은 4자리여야 합니다.');
            return;
        }

        const grade = parseInt(student_code[0], 10);
        const classnum = parseInt(student_code[1], 10);

        try {
            const response = await fetch('https://dsmb.api.goding.us/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password,
                    grade,
                    classnum,
                    student_code,
                    name,
                    handle
                })
            });

            const data = await response.json();
            if (data.success) {
                alert('회원가입에 성공했습니다. 로그인해주세요.');
                window.location.href = '/login';
            } else {
                alert(`회원가입에 실패했습니다: ${data.message}`);
            }
        } catch (error) {
            console.error('회원가입 중 오류가 발생했습니다:', error);
            alert('회원가입 중 오류가 발생했습니다.');
        }
    };

    return (
        <div>
            <div className="bg-white rounded-xl mx-4">
                <div className="flex items-center rounded-t-lg p-4">
                    <div className="text-2xl font-bold text-gray-600">회원가입<i className="bi bi-person-plus-fill ml-2" /></div>
                    <div className="flex-grow" />
                    <div className="text-gray-600 text-sm font-semibold">
                        이미 계정이 있으신가요? <Link to="/login" className="underline font-bold">로그인</Link>
                    </div>
                </div>
                <div className="p-4">
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <label htmlFor="email" className="text-gray-600 ml-1 font-semibold">이메일</label>
                                <div className="flex-grow" />
                            </div>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="rounded-lg p-2 bg-white border border-gray-600"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <label htmlFor="handle" className="text-gray-600 ml-1 font-semibold">아이디</label>
                                <div className="flex-grow" />
                            </div>
                            <input
                                type="text"
                                id="handle"
                                name="handle"
                                value={formData.handle}
                                onChange={handleChange}
                                className="rounded-lg p-2 bg-white border border-gray-600"
                                required
                            />
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-col gap-1 w-1/2">
                                <div className="flex">
                                    <label htmlFor="password" className="text-gray-600 ml-1 font-semibold">비밀번호</label>
                                    <div className="flex-grow" />
                                </div>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="rounded-lg p-2 bg-white border border-gray-600"
                                    required
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-1/2">
                                <div className="flex">
                                    <label htmlFor="password_confirm" className="text-gray-600 ml-1 font-semibold">비밀번호 확인</label>
                                    <div className="flex-grow" />
                                </div>
                                <input
                                    type="password"
                                    id="password_confirm"
                                    name="password_confirm"
                                    value={formData.password_confirm}
                                    onChange={handleChange}
                                    className="rounded-lg p-2 bg-white border border-gray-600"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-col gap-1 w-1/2">
                                <div className="flex">
                                    <label htmlFor="student_code" className="text-gray-600 ml-1 font-semibold">학번</label>
                                    <div className="flex-grow" />
                                </div>
                                <input
                                    type="number"
                                    id="student_code"
                                    name="student_code"
                                    value={formData.student_code}
                                    onChange={handleChange}
                                    className="rounded-lg p-2 bg-white border border-gray-600"
                                    required
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-1/2">
                                <div className="flex">
                                    <label htmlFor="name" className="text-gray-600 ml-1 font-semibold">이름</label>
                                    <div className="flex-grow" />
                                </div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="rounded-lg p-2 bg-white border border-gray-600"
                                    required
                                />
                            </div>
                        </div>
                        <button type="submit" className="mt-4 bg-primary text-white font-semibold p-2 rounded-lg">
                            회원가입
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
