import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Nav from './Nav';
import Home from './Home';
import Problem from './Problem';
import Register from './Register';
import Login from './Login';
import Newproblem from './Newproblem';
import Solve from './Solve';
import Solution from './Solution';
import User from './User';
import Footer from './Footer';
import Ranking from './Ranking';

const Structure = () => {
    return (
        <>
            <BrowserRouter>
                <div className="xl:px-32 xl:py-6">
                    <Nav />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/problem/:pid" element={<Problem />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/new/problem" element={<Newproblem />} />
                        <Route path="/solve/:pid" element={<Solve />} />
                        <Route path="/solution/:sid" element={<Solution />} />
                        <Route path="/user/:handle" element={<User />} />
                        <Route path="/ranking" element={
                            <>
                                <div className="flex flex-col px-4">
                                    <span className="text-3xl font-semibold text-gray-800 mb-4">랭킹</span>
                                    <Ranking />
                                </div>
                            </>
                        } />
                    </Routes>
                    <Footer />
                </div>
            </BrowserRouter>
        </>
    );
}

export default Structure;