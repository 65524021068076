import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import ProgressView from './ProgressView';

moment.tz.setDefault("Asia/Seoul");

const Problem = () => {
    const { pid } = useParams();
    const [problem, setProblem] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);

    useEffect(() => {
        const fetchProblemData = async () => {
            try {
                const problemResponse = await fetch(`https://dsmb.api.goding.us/problem/${pid}`);
                const problemData = await problemResponse.json();

                if (!problemResponse.ok || !problemData.success) {
                    throw new Error(problemData.message || 'Problem not found.');
                }

                setProblem(problemData.problem);

                // Fetch PDF after getting problem data
                const pdfResponse = await fetch(`https://dsmb.api.goding.us/problem/file/${pid}`);
                if (!pdfResponse.ok) {
                    throw new Error('Error fetching PDF file.');
                }
                const pdfBlob = await pdfResponse.blob(); // Get as Blob

                // Create object URL for the PDF
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfFile(pdfUrl);

            } catch (error) {
                console.error('Error fetching problem or PDF:', error);
            }
        };

        fetchProblemData();

        // Cleanup function to revoke the object URL when the component unmounts
        return () => {
            if (pdfFile) {
                URL.revokeObjectURL(pdfFile);
            }
        };
    }, [pid]);

    const handleDelete = async () => {
        if (window.confirm('정말로 이 문제를 삭제하시겠습니까?')) {
            try {
                const handle = localStorage.getItem('handle');
                const credential = localStorage.getItem('credential');

                const response = await fetch('https://dsmb.api.goding.us/admin/deleteProblem', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ handle, credential, pid })
                });

                const data = await response.json();
                if (data.success) {
                    alert('문제 삭제가 완료되었습니다.');
                    window.location.href = '/';
                } else {
                    alert(`문제 삭제에 실패했습니다: ${data.message}`);
                }
            } catch (error) {
                console.error('문제 삭제 중 오류가 발생했습니다:', error);
                alert('문제 삭제 중 오류가 발생했습니다.');
            }
        }
    };

    return (
        <div className="px-4">
            {problem ? (
                <div className="bg-white rounded-lg shadow-md p-6 border">
                    <div className="flex items-center mb-2">
                        <h1 className="text-3xl font-bold mb-4 text-gray-800">{problem.problem_nm}</h1>
                        <div className="flex-grow"></div>
                        <h1 className="text-xl font-bold mb-4 rounded-lg text-white bg-gray-600 p-2">#{problem.pid}</h1>
                    </div>

                    {localStorage.getItem('isAdmin') === '1' && (
                        <div className="border border-gray-400 p-4 rounded-xl mb-4">
                            <div className="flex text-gray-600 font-semibold mb-2">
                                <i className="bi bi-tools mr-2" /> 관리자 도구
                            </div>
                            <div className="flex text-red-600 font-semibold text-sm">
                                <button
                                    onClick={handleDelete}
                                    className="hover:underline"
                                >
                                    <i className="bi bi-trash mr-1" />문제 삭제
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Problem Metadata */}
                    <div className="mb-6">
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-person-fill mr-1" />출제자</span>
                            <span className="ml-2 font-semibold">{problem.name}님</span>

                            {problem.isAdmin ? (
                                <>
                                    <i className="text-sm text-primary bi bi-patch-check-fill ml-1"></i>
                                    <span className="ml-1 text-primary font-semibold">관리자</span>
                                </>
                            ) : null}
                            <span className="ml-2 text-gray-500">{problem.point}P</span>
                        </div>
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-calendar-event-fill mr-1" />출제일</span>
                            <span className="ml-2">
                                {moment(problem.problem_timestamp).format('YYYY년 MM월 DD일 HH시 mm분')}
                            </span>
                        </div>
                        <div className="flex items-center text-gray-600 text-sm">
                            <span className="font-semibold text-gray-800"><i className="bi bi-tag-fill mr-1" />태그</span>
                            <span className="ml-2">
                                {problem.problem_tags != "" ? (problem.problem_tags.split(',').map((tag, idx) => (
                                    <span key={idx} className="bg-gray-200 text-gray-800 rounded-full px-2 py-1 text-sm mr-2">{tag}</span>
                                ))) : null}
                            </span>
                        </div>
                    </div>
                    <div className="mb-8">
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <h3 className="text-xl font-semibold mb-2">문제 설명</h3>
                            <div className="flex-grow" />
                        </div>
                        <p className="text-gray-800 text-left">{problem.problem_desc}</p>
                        <div className="flex mt-2">
                            <Link to={`/solve/${problem.pid}`} className="text-primary hover:bg-main-100 font-semibold border p-2 border-primary rounded-xl mt-4">문제 풀기<i className="bi bi-pencil-fill ml-2" /></Link>
                        </div>
                    </div>

                    <div className="border rounded-lg p-2">
                        {pdfFile ? (
                            <iframe
                                src={pdfFile} // Use the object URL here
                                title="Problem PDF"
                                width="100%"
                                height="800px"
                            />
                        ) : (
                            <div className="text-gray-600"><ProgressView text="PDF 불러오는 중" length="3" /></div>
                        )}
                    </div>

                </div>
            ) : (
                <div><ProgressView text="문제 불러오는 중" length="3" /></div>
            )}
        </div>
    );
};

export default Problem;
