import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import { useDropzone } from 'react-dropzone';
import ProgressView from './ProgressView';

moment.tz.setDefault("Asia/Seoul");

const Solve = () => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const [problem, setProblem] = useState(null);
  const [solutionDesc, setSolutionDesc] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
    accept: 'application/pdf',
    multiple: false,
  });

  useEffect(() => {
    const fetchProblemData = async () => {
      try {
        const response = await fetch(`https://dsmb.api.goding.us/problem/${pid}`);
        if (!response.ok) {
          throw new Error('Problem not found');
        }

        const data = await response.json();
        setProblem(data.problem);
      } catch (error) {
        console.error('Error fetching problem:', error);
        // Handle error, maybe redirect to error page
      }
    };

    fetchProblemData();
  }, [pid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      alert('PDF 파일을 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('pdfFile', selectedFile);
    formData.append('solver_handle', localStorage.getItem('handle'));
    formData.append('credential', localStorage.getItem('credential'));
    formData.append('target_pid', pid);
    formData.append('solution_desc', solutionDesc);

    try {
      const response = await fetch('https://dsmb.api.goding.us/solution/upload', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        alert('풀이 제출이 완료되었습니다.');
        navigate(`/solution/${data.solutionId}`);
      } else {
        alert(`풀이 제출에 실패했습니다: ${data.message}`);
      }
    } catch (error) {
      console.error('풀이 제출 중 오류 발생:', error);
      alert('풀이 제출 중 오류 발생.');
    }
  };

  return (
    <div className="px-4">
      {problem ? (
        <div className="bg-white rounded-lg shadow-md p-6 border">
          <div className="flex items-center mb-2">
            <h1 className="text-3xl font-bold mb-4 text-gray-800">{problem.problem_nm}</h1>
            <div className="flex-grow" />
            <h1 className="text-xl font-bold mb-4 rounded-lg text-white bg-gray-600 p-2">#{problem.pid}</h1>
          </div>

          <div className="mb-6">
            <div className="flex items-center text-gray-600 text-sm mb-2">
              <span className="font-semibold text-gray-800"><i className="bi bi-person-fill mr-1" />출제자</span>
              <span className="ml-2 font-semibold">{problem.name}님</span>
              <span className="ml-2 text-gray-500">{problem.point}P</span>
            </div>
            <div className="flex items-center text-gray-600 text-sm">
              <span className="font-semibold text-gray-800"><i className="bi bi-calendar-event-fill mr-1" />출제일</span>
              <span className="ml-2">
                {moment(problem.problem_timestamp).format('YYYY년 MM월 DD일 HH시 mm분')}
              </span>
            </div>
          </div>

          <div className="border border-gray-800 p-4 rounded mb-4">
            <div className="flex text-gray-800 font-semibold mb-2">
              <i className="bi bi-info-circle mr-1" /> 문제 설명
            </div>
            <div className="text-gray-600 flex">
              {problem.problem_desc}
            </div>
            <div className="flex text-primary font-semibold mt-4">
              <Link to={`/problem/${problem.pid}`} className="text-gray-800 font-semibold hover:underline border p-2 border-gray-800 rounded-xl">문제 보기<i className="bi bi-arrow-up-right ml-2" /></Link>
            </div>
          </div>


          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="solution_desc" className="text-gray-600 ml-1 font-semibold">풀이 설명 (간단한 제목)</label>
                <div className="flex-grow" />
              </div>
              <textarea
                id="solution_desc"
                value={solutionDesc}
                onChange={(e) => setSolutionDesc(e.target.value)}
                className="rounded-lg p-2 bg-white border-2 border-gray-600"
                rows="1"
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="pdfFile" className="text-gray-600 ml-1 font-semibold">풀이 파일 업로드 (PDF)</label>
                <div className="flex-grow" />
              </div>
              <div 
                {...getRootProps()}
                className={`rounded-lg p-2 bg-white border-2 border-gray-600 ${
                  isDragActive ? 'border-dashed bg-gray-100' : ''
                }`}
              >
                <input {...getInputProps()} />
                {selectedFile ? (
                  <p>{selectedFile.name}</p>
                ) : (
                  <p>{isDragActive ? '이곳에 파일을 놓으세요' : 'PDF 파일을 여기에 끌어다 놓거나 클릭해 업로드하세요'}</p>
                )}
              </div>
            </div>
            <button type="submit" className="mt-4 bg-primary text-white font-semibold p-2 rounded-lg">
              제출하기
            </button>
          </form>
        </div>
      ) : (
        <div className='text-primary'><ProgressView text="문제 불러오는 중" length="3" /></div>
      )}
    </div>
  );
};

export default Solve;