import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Newproblem = () => {
  const [formData, setFormData] = useState({
    problem_nm: '',
    problem_desc: '',
    problem_tags: '',
    pdfFile: null,
  });

  const handleDrop = (acceptedFiles) => {
    setFormData({
      ...formData,
      pdfFile: acceptedFiles[0],
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'application/pdf',
    multiple: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handle = localStorage.getItem('handle');
    const credential = localStorage.getItem('credential');

    const formDataToSend = new FormData();
    formDataToSend.append('uploader_handle', handle);
    formDataToSend.append('credential', credential);
    formDataToSend.append('problem_nm', formData.problem_nm);
    formDataToSend.append('problem_desc', formData.problem_desc);
    formDataToSend.append('problem_tags', formData.problem_tags);
    formDataToSend.append('pdfFile', formData.pdfFile);

    try {
      const response = await fetch('https://dsmb.api.goding.us/problem/upload', {
        method: 'POST',
        body: formDataToSend,
      });

      const data = await response.json();
      if (data.success) {
        alert('문제가 성공적으로 업로드되었습니다.');
        window.location.href = '/';
      } else {
        alert(`문제 업로드에 실패했습니다: ${data.message}`);
      }
    } catch (error) {
      console.error('문제 업로드 중 오류가 발생했습니다:', error);
      alert('문제 업로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <div>
      <div className="bg-white rounded-xl mx-4">
        <div className="flex items-center rounded-t-lg py-4 mx-4">
          <div className="text-gray-600 text-2xl font-bold">
            새 문제 등록하기<i className="bi bi-upload ml-2" />
          </div>
        </div>
        <div className="p-4">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="problem_nm" className="text-gray-600 ml-1 font-semibold">
                  문제 이름
                </label>
                <div className="flex-grow" />
              </div>
              <input
                type="text"
                id="problem_nm"
                name="problem_nm"
                value={formData.problem_nm}
                onChange={handleChange}
                className="rounded-lg p-2 bg-white border border-gray-600"
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="problem_desc" className="text-gray-600 ml-1 font-semibold">
                  문제 설명
                </label>
                <div className="flex-grow" />
              </div>
              <textarea
                id="problem_desc"
                name="problem_desc"
                value={formData.problem_desc}
                onChange={handleChange}
                className="rounded-lg p-2 bg-white border border-gray-600"
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="problem_tags" className="text-gray-600 ml-1 font-semibold">
                  태그 (콤마로 구분)
                </label>
                <div className="flex-grow" />
              </div>
              <input
                type="text"
                id="problem_tags"
                name="problem_tags"
                value={formData.problem_tags}
                onChange={handleChange}
                className="rounded-lg p-2 bg-white border border-gray-600"
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <label htmlFor="pdfFile" className="text-gray-600 ml-1 font-semibold">
                  문제 파일 (PDF)
                </label>
                <div className="flex-grow" />
              </div>
              <div
                {...getRootProps()}
                className={`rounded-lg p-2 bg-white border border-gray-600 ${isDragActive ? 'border-dashed bg-gray-100' : ''
                  }`}
              >
                <input {...getInputProps()} />
                {formData.pdfFile ? (
                  <p>{formData.pdfFile.name}</p>
                ) : (
                  <p className='text-gray-600'>
                    {isDragActive ? '이곳에 파일을 놓으세요' : 'PDF 파일을 여기에 끌어다 놓거나 클릭해 업로드하세요'}
                  </p>
                )}
              </div>
            </div>
            <button type="submit" className="mt-4 bg-primary text-white font-semibold p-2 rounded-lg">
              업로드
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newproblem;