import React, { useState, useEffect } from 'react';

const ProgressView = (props) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => {
                if (prevDots.length < props.length) {
                    return prevDots + '.';
                } else {
                    return '';
                }
            });
        }, 150);

        return () => clearInterval(interval);
    }, [props.length]);

    return (
        <div>
            <p className="font-semibold">{props.text + dots}</p>
        </div>
    );
}

export default ProgressView;
