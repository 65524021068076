import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import ProgressView from './ProgressView';

moment.tz.setDefault("Asia/Seoul");

const User = () => {
    const { handle } = useParams();
    const [user, setUser] = useState(null);
    const [solutions, setSolutions] = useState([]);
    const [problems, setProblems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://dsmb.api.goding.us/public/user/${handle}`);
                const data = await response.json();

                if (!response.ok || !data.success) {
                    throw new Error(data.message || 'User not found.');
                }

                setUser(data.user);
                setSolutions(data.solutions);
                setProblems(data.problems);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [handle]);

    return (
        <div className="px-4 pb-4">
            {loading ? (
                <div><ProgressView text="사용자 정보 불러오는 중" length="3" /></div>
            ) : user ? (
                <div className="bg-white rounded-lg shadow-md p-6 border">
                    <div className="flex">
                        <h1 className="text-3xl font-bold mb-4 text-gray-800">{user.name}님</h1>
                        <div className="flex-grow"></div>
                        <span className="text-gray-600 text-sm">@{user.handle}</span>
                    </div>

                    {/* User Metadata */}
                    <div className="mb-6">
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-person-badge-fill mr-1" />학번</span>
                            <span className="ml-2 font-semibold">{user.student_code}</span>
                        </div>
                        <div className="flex items-center text-gray-600 text-sm mb-2">
                            <span className="font-semibold text-gray-800"><i className="bi bi-star-fill mr-1" />포인트</span>
                            <span className="ml-2 font-semibold">{user.point}P</span>
                        </div>
                        {user.isAdmin ? (
                            <div className="flex items-center text-sm mb-2">
                                <div className="bg-gray-600 rounded-xl p-1 px-2">
                                    <i className="text-sm text-white bi bi-patch-check-fill mr-1"></i>
                                    <span className="ml-1 text-white font-semibold">관리자</span>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {/* Solutions Section */}
                    <div className="flex">
                        <h2 className="text-2xl font-semibold text-gray-600 mb-4">제출한 솔루션</h2>
                        <div className="flex-grow"></div>
                    </div>
                    {solutions.length === 0 ? (
                        <p className="text-gray-600">제출한 솔루션이 없습니다.</p>
                    ) : (
                        <ul>
                            {solutions.map((solution) => (
                                <li key={solution.sid} className="pt-3 py-2 mb-2 border-t">
                                    <Link to={`/solution/${solution.sid}`}>
                                        <div className="flex">
                                            <Link to={`/problem/${solution.target_pid}`} className="text-lg font-semibold text-gray-800 rounded-lg">
                                                <span>#{solution.target_pid} - {solution.problem_nm.substring(0, 20)}</span>
                                            </Link>
                                            <div className="flex-grow" />
                                            <p className="text-sm text-gray-600 mr-3">{moment(solution.solution_timestamp).fromNow()}</p>
                                        </div>
                                        <div className="flex py-2">
                                            <span className="text-yellow-500 font-semibold text-left text-lg line-clamp-2">
                                                <i className="bi bi-star-fill mr-1" />{solution.rating}</span>
                                            <span className="text-white font-semibold text-left text-lg line-clamp-2 ml-2">
                                                {solution.solution_desc}</span>
                                            <div className="flex-grow"></div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}

                    {/* Problems Section */}
                    <div className="flex">
                        <h2 className="text-2xl font-semibold text-gray-600 mt-8 mb-4">출제한 문제</h2>
                        <div className="flex-grow"></div>
                    </div>
                    {problems.length === 0 ? (
                        <p className="text-gray-600">출제한 문제가 없습니다.</p>
                    ) : (
                        <ul>
                            {problems.map((problem) => (
                                <li key={problem.pid} className="border-t border-gray-300 py-2">
                                    <Link to={`/problem/${problem.pid}`}>
                                        <div className="flex mb-1">
                                            <p className="text-lg font-semibold text-left line-clamp-2">{problem.problem_nm}</p>
                                            <div className="flex-grow"></div>
                                        </div>
                                        <p className="text-gray-800 text-left line-clamp-2">{problem.problem_desc}</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ) : (
                <div>사용자를 찾을 수 없습니다.</div>
            )}
        </div>
    );
}

export default User;